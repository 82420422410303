export const realNameRule = /^[\u4e00-\u9fa5]{2,20}$/;

export const passwordRule =
  /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{6,18}$/;

export const idCardRule =
  /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

export const smsCodeRule = /^[0-9]{4}$/;

export const companyNameRule = /^[\u4e00-\u9fa5]{2,}$/;

export const companyAddressRule = /^[\u4e00-\u9fa5]{2,}$/;

export const companySocialCodeRule = /^[A-Za-z0-9]{9,17}$/;

export const phoneRule = /^[1]([3-9])[0-9]{9}$/;

export function validatePwd(rule, value, callback) {
  let reg = passwordRule;
  if (!value) {
    callback();
  } else if (value.length < 6 || value.length > 18) {
    callback("密码长度为6-18位");
  } else if (!reg.test(value)) {
    callback("密码必须包含数字、大小写字母、特殊符号中的三种");
  } else {
    callback();
  }
}
